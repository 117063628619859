import Hero from "../layout/hero";
import { Helmet } from "react-helmet";

const title = "About us";
const subTitle =
  "Sørwest is a sawmill that takes great pride in the quality of its timber products. Our team of skilled woodsmen approach their work with a real passion and appreciation for the beauty of natural wood.";

export default function About() {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={subTitle} />
      </Helmet>

      <Hero title={title} subTitle={subTitle} />

      <div class="container py-5 px-4 px-lg-3">
        <div class="row">
          <div class="col-lg-7">
            <p>
              We believe that every piece of timber is unique and deserves to be
              treated with care and respect.
            </p>

            <p>
              Our sawmill is a state-of-the-art, small-scale operation that uses
              a Woodmizer electric sawmill to produce high-quality timber
              products. This cutting-edge technology allows us to expertly cut
              structural timber, cladding, slabs, and flooring profiles to your
              exact specifications.
            </p>

            <p>
              In addition to our bespoke cutting services, we can also re-saw
              timber and cut logs to size, using your own wood if preferred. We
              understand that every project is different, and we are always
              happy to work with you to find the perfect solution for your
              needs.
            </p>

            <p>
              At Sørwest, we take great pride in the quality of our timber
              products. We carefully select only the best quality timber, and we
              take the time to prepare each piece with great care and attention
              to detail. Our team of woodsmen enjoy the process as much as the
              end product, and they take great pride in their work.
            </p>

            <p>
              We always keep a wide range of timber products in stock, and we
              are able to offer a quick turnaround on orders. In addition, we
              store air-dried cladding that is ready to collect. This means that
              we are able to offer our customers a fast, efficient service,
              without compromising on quality.
            </p>

            <p>
              So, whether you are a builder, a DIY enthusiast, or a homeowner
              looking to add some natural beauty to your property, Sørwest are
              here to help!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
