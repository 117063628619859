import "./App.css";
import { Outlet } from "react-router-dom";

// Layout
import Alert from "./layout/alert";
import Header from "./layout/header";
import Footer from "./layout/footer";

function App() {
  return (
    <>
      <Alert />
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default App;
