import Hero from "../../layout/hero";
import { Helmet } from "react-helmet";

const title = "Cladding";
const subTitle =
  "Profiled planks used for cladding external walls, offering protection and an attractive finish. Available unfinished, painted in a range of colours or charred.";

const paintedCladding = [
  {
    img: "./img/products/cladding-painted-red.jpg",
    description: "Red",
  },
  {
    img: "./img/products/cladding-painted-blue.jpg",
    description: "Blue",
  },
  {
    img: "./img/products/cladding-painted-cream.jpg",
    description: "Cream",
  },
  {
    img: "./img/products/cladding-painted-black.jpg",
    description: "Black",
  },
  {
    img: "./img/products/cladding-painted-white.jpg",
    description: "White",
  },
  {
    img: "./img/products/cladding-painted-green.jpg",
    description: "Green",
  },
];

const otherCladding = [
  {
    img: "./img/products/cladding-moulded.jpg",
    description:
      "Untreated, moulded cladding. A staple in our range of cladding products.",
  },
];

const kalmerCladding = [
  { img: "./img/products/kalmer.jpg" },
  { img: "./img/products/kalmers-1.jpg" },
  { img: "./img/products/kalmers-2.jpg" },
];

const charredCladding = [
  { img: "./img/products/charred_cladding.jpeg" },
  { img: "./img/products/charred_cladding_3.jpeg" },
];

export default function Cladding() {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={subTitle} />
      </Helmet>

      <Hero title={title} subTitle={subTitle} />

      <div class="container py-5 px-4 px-lg-3" index="other">
        <div class="container">
          <div class="d-flex mb-3">
            <div class="flex-grow-1 d-md-none">
              <hr class="border-2 border-top me-3" />
            </div>
            <div class="flex-shrink-1">
              <h2 class="h4">Cladding</h2>
            </div>
            <div class="flex-grow-1">
              <hr class="border-2 border-top ms-3" />
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row g-4">
            {otherCladding.map((item, index) => (
              <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                <div class="card text-bg-dark h-100 d-inline-block">
                  <img
                    src={item.img}
                    class="card-img-top"
                    alt="Sawn timber boards"
                  />
                  <div class="card-body px-4 py-4">
                    <p class="card-text">{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div class="container py-5 px-4 px-lg-3" index="painted">
        <div class="container">
          <div class="d-flex mb-3">
            <div class="flex-grow-1 d-md-none">
              <hr class="border-2 border-top me-3" />
            </div>
            <div class="flex-shrink-1">
              <h2 class="h4">Painted Cladding</h2>
            </div>
            <div class="flex-grow-1">
              <hr class="border-2 border-top ms-3" />
            </div>
          </div>
        </div>
        <div class="container mb-4">
          <p>
            Enhance your space with a range of colours, meticulously applied to
            our high-quality timber. Elevate your property with this durable
            finish, painted on your choice of three or four sides. Available in
            a wide range of colours. Get in touch to discuss your needs.
          </p>
        </div>
        <div class="container">
          <div class="row g-4">
            {paintedCladding.map((item, index) => (
              <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                <div class="card text-bg-dark h-100 d-inline-block">
                  <img
                    src={item.img}
                    class="card-img-top"
                    alt="Sawn timber boards"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div class="container py-5 px-4 px-lg-3" index="charred">
        <div class="container">
          <div class="d-flex mb-3">
            <div class="flex-grow-1 d-md-none">
              <hr class="border-2 border-top me-3" />
            </div>
            <div class="flex-shrink-1">
              <h2 class="h4">Charred / Burnt Cladding</h2>
            </div>
            <div class="flex-grow-1">
              <hr class="border-2 border-top ms-3" />
            </div>
          </div>
        </div>
        <div class="container mb-4">
          <p>
            We are pleased to be able to offer cladding crafted with the ancient
            Japanese art of charring. Its durability withstands harsh weather
            while adding depth, character, and sophistication to any
            architectural project.
          </p>
        </div>
        <div class="container">
          <div class="row g-4">
            {charredCladding.map((item, index) => (
              <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                <div class="card text-bg-dark h-100 d-inline-block">
                  <img
                    src={item.img}
                    class="card-img-top"
                    alt="Sawn timber boards"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div class="container py-5 px-4 px-lg-3" index="kalmer">
        <div class="container">
          <div class="d-flex mb-3">
            <div class="flex-grow-1 d-md-none">
              <hr class="border-2 border-top me-3" />
            </div>
            <div class="flex-shrink-1">
              <h2 class="h4">Kalmer Cladding</h2>
            </div>
            <div class="flex-grow-1">
              <hr class="border-2 border-top ms-3" />
            </div>
          </div>
        </div>
        <div class="container mb-4">
          <p>
            These inexpensive "kalmarbrædder" live edge boards are very popular
            in Scandinavia and great for cabins, log stores, cladding etc. Sold
            in packs of 50 boards available in 3600 & 4800 lengths 20mm thick.
            Board widths vary and average 150-250mm wide.
          </p>
          <p>
            Kalmer boards offer endless possibilities for creative projects,
            ensuring exceptional durability and stunning aesthetics in every
            application.
          </p>
        </div>
        <div class="container">
          <div class="row g-4">
            {kalmerCladding.map((item, index) => (
              <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                <div class="card text-bg-dark h-100 d-inline-block">
                  <img
                    src={item.img}
                    class="card-img-top"
                    alt="Sawn timber boards"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
