import { Link } from "react-router-dom";

// Layout
import Navigation from "./nav";

export default function Header() {
	return (
		<header class="sticky-top">
			<nav
				class="navbar navbar-expand-lg bg-body-tertiary"
				data-bs-theme="dark"
			>
				<div class="container-fluid d-flex justify-content-start">
					<div class="navbar-toggler">
						<button
							class="btn btn-dark py-2 px-3"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarSupportedContent"
							aria-controls="navbarSupportedContent"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="28"
								height="28"
								fill="currentColor"
								class="bi bi-text-left"
								viewBox="0 0 16 16"
							>
								<path
									fill-rule="evenodd"
									d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
								/>
							</svg>
						</button>
					</div>

					<Link
						class="navbar-brand text-uppercase ms-3 ms-lg-0 pt-0"
						href="./"
					>
						<img
							src="./img/logo.svg"
							alt="Sørwest"
							width="140"
							height="auto"
						/>
					</Link>
					<a
						href="tel:07866680343"
						class="btn btn-success btn-sm d-lg-none ms-auto"
						aria-label="Call to order"
					>
						Call to order
					</a>
					<div
						class="collapse navbar-collapse"
						id="navbarSupportedContent"
					>
						<Navigation />
						<a
							href="tel:07866680343"
							class="btn btn-success d-none d-lg-block"
							aria-label="Call to order"
						>
							Call to order
						</a>
						<div class="p-3 d-lg-none">
							<a
								href="mailto:will@sorwest.com?subject=Website enquiry"
								class="btn btn-primary w-100"
								aria-label="Email us"
							>
								Email us
							</a>
						</div>
						<a
							href="mailto:will@sorwest.com?subject=Website enquiry"
							class="btn btn-secondary ms-lg-2 d-none d-lg-block"
							aria-label="Email us"
						>
							Email us
						</a>
					</div>
				</div>
			</nav>
		</header>
	);
}
