export default function Alert() {
	return (
		<div class="navbar bg-body-tertiary p-0">
			<div class="container-fluid bg-dark d-flex justify-content-center text-center px-5">
				<span class="navbar-text text-light">
					<small>
						<strong>Painted weatherboards</strong> are now available
						in a new range of colours
					</small>
				</span>
			</div>
		</div>
	);
}
