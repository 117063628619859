export default function Footer() {
	return (
		<>
			{" "}
			<footer class="text-muted py-5 bg-light">
				<div class="container">
					<div class="row flex-column flex-md-row px-3 px-lg-0">
						<div class="col">
							<p>
								All content &copy; Sorwest Ltd, Registered
								address: The Barn, High Meadows, Morwenstow,
								Bude, Cornwall EX23 9PH
							</p>
						</div>
						<div class="col">
							<p class="float-md-end">
								<a href="#top" class="btn btn-secondary">
									Back to top
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="16"
										fill="currentColor"
										class="bi bi-arrow-up"
										viewBox="0 0 16 16"
									>
										<path
											fill-rule="evenodd"
											d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
										/>
									</svg>
								</a>
							</p>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}
