import Hero from "../layout/hero";
import { Helmet } from "react-helmet";

const title = "Our delivery radius";
const subTitle =
  "At Sorwest, we pride ourselves on delivering the best quality products to our customers in the most convenient way possible. That's why we offer our own delivery service to ensure that you receive your orders as quickly and efficiently as possible.";

export default function Delivery() {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={subTitle} />
      </Helmet>

      <Hero title={title} subTitle={subTitle} />

      <div class="container py-5 px-4 px-lg-3">
        <div class="row flex-lg-row-reverse">
          <div class="col-lg-5">
            <p>
              <img
                src="./img/cornwall-devon.jpg"
                class="img-fluid rounded"
                alt="Cornwall and Devon"
              />
            </p>
          </div>
          <div class="col-lg-7">
            <p>
              Our delivery radius covers all of Devon and Cornwall, so no matter
              where you are in these counties, you can benefit from our service.
            </p>

            <p>
              Whether you're located in the centre of Plymouth or rural Bodmin,
              our delivery driver will ensure that your orders are delivered on
              time. We understand that receiving your orders in a timely manner
              is important to you, and that's why we go above and beyond to make
              sure that you receive your orders as soon as possible.
            </p>

            <p>
              Our delivery driver is well-versed in navigating the roads of
              Devon and Cornwall, so you can rest assured that your orders will
              be delivered to you safely and efficiently. We take great care in
              ensuring that your products are handled with the utmost care
              during transportation, so you can receive them in the same
              pristine condition that they left our facility in.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
