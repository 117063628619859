export default function Hero(props) {
	return (
		<div class="container-fluid hero py-5">
			<div class="container">
				<div class="row">
					<div class="col-lg-7 text-light">
						<h1 class="display-3 mb-3">{props.title}</h1>
						<p class="lead">{props.subTitle}</p>
					</div>
				</div>
			</div>
		</div>
	);
}
