import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <div class="container-fluid hero py-5">
        <div class="container pb-5">
          <div class="row">
            <div class="col-12 col-lg-7 align-self-center">
              <h1 class="display-3 text-light mb-4 mb-lg-5">
                Devon and Cornwall's trusted sawmill for construction and DIY
              </h1>
              <div class="row mb-5 mb-lg-0">
                <div class="text-light fs-5 d-lg-none mb-2">
                  To discuss your order:
                </div>
                <div class="col-lg-auto">
                  <a
                    href="tel:07866680343"
                    class="btn btn-lg btn-success py-3 px-4"
                    aria-label="Call to order"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-telephone-outbound-fill me-2 d-lg-none"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
                      />
                    </svg>
                    Call Will on 07866680343
                  </a>
                </div>
                <div class="col-lg-auto align-self-center text-light fs-5 mt-3 mt-lg-0 d-none d-lg-inline">
                  to discuss your order
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-5">
              <div
                id="heroSlider"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="carousel-indicators">
                  <button
                    type="button"
                    data-bs-target="#heroSlider"
                    data-bs-slide-to="0"
                    class="active"
                    aria-current="true"
                    aria-label="Slide 1"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#heroSlider"
                    data-bs-slide-to="1"
                    aria-label="Slide 2"
                  ></button>
                  <button
                    type="button"
                    data-bs-target="#heroSlider"
                    data-bs-slide-to="2"
                    aria-label="Slide 3"
                  ></button>
                </div>
                <div class="carousel-inner rounded">
                  <div
                    class="carousel-item active ratio ratio-4x3"
                    style={{ backgroundImage: "url('./img/beams.webp')" }}
                  ></div>
                  <div
                    class="carousel-item ratio ratio-4x3"
                    style={{ backgroundImage: "url('./img/boards.webp')" }}
                  ></div>
                  <div
                    class="carousel-item ratio ratio-4x3"
                    style={{
                      backgroundImage: "url('./img/painted_boards.webp')",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="d-flex mb-3">
            <div class="flex-grow-1 d-md-none">
              <hr class="border-2 border-top me-3" />
            </div>
            <div class="flex-shrink-1">
              <h2 class="h4 text-light">We specialise in</h2>
            </div>
            <div class="flex-grow-1">
              <hr class="border-2 border-top ms-3" />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
              <div class="card text-bg-dark h-100 d-inline-block">
                <img
                  src="./img/products/boards.jpeg"
                  class="card-img-top"
                  alt="Sawn timber boards"
                />
                <div class="card-body px-4 py-4">
                  <h3 class="card-title">Sawn Timber</h3>
                  <p class="card-text">
                    Our sawn timber is sustainably sourced and cut to size for
                    both construction and DIY. Available in a variety of
                    species, grades and finishes to suit your needs.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
              <div class="card text-bg-dark h-100 d-inline-block">
                <img
                  src="./img/products/charred_cladding_3.jpeg"
                  class="card-img-top"
                  alt="Charred cladding"
                />
                <div class="card-body px-4 py-4">
                  <h3 class="card-title">Cladding</h3>
                  <p class="card-text">
                    Profiled planks used for cladding external walls, offering
                    protection and an attractive finish. Available{" "}
                    <b>unfinished, painted or charred</b> in a range of colours.
                  </p>
                  <Link class="btn btn-primary" to="/cladding">
                    View styles
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="card text-bg-dark h-100 d-inline-block">
                <img
                  src="./img/products/pre_aged_douglas_decking.jpeg"
                  class="card-img-top"
                  alt="Pre-aged douglas decking"
                />
                <div class="card-body px-4 py-4">
                  <h3 class="card-title">Decking</h3>
                  <p class="card-text">
                    Our decking boards create a durable and attractive outdoor
                    living space. Available in a range of finishes to suit any
                    style, our decking boards are the ideal choice for
                    homeowners and businesses alike.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container py-5 px-4 px-lg-3">
        <div class="row">
          <div class="col-12 mb-3 pt-lg-5">
            <h2 class="h1 mb-lg-5 text-center">Let us care about your wood</h2>
          </div>
        </div>

        <div class="row mb-lg-5">
          <div class="col-md-4 mb-3 mb-lg-0">
            <div class="card p-3 border-success h-100">
              <div class="card-body">
                <h3 class="h4 card-title text-success">Sustainable sourcing</h3>
                <p class="card-text">
                  Our sawn timber, decking boards, and weatherboards are sourced
                  from responsibly managed forests, ensuring environmental
                  sustainability for future generations.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-4 mb-3 mb-lg-0">
            <div class="card p-3 border-success h-100">
              <div class="card-body">
                <h3 class="h4 card-title text-success">Expert advice</h3>
                <p class="card-text">
                  Our knowledgeable team is on hand to provide expert advice on
                  the best products and solutions for each project, ensuring you
                  make the right decisions.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-4 mb-3 mb-lg-0">
            <div class="card p-3 border-success h-100">
              <div class="card-body">
                <h3 class="h4 card-title text-success">Local business</h3>
                <p class="card-text">
                  Our sawmill is proudly based in Bude, North Cornwall which
                  means we can offer our local customers competitive pricing,
                  quick turnaround times, and support the local economy.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-none d-md-flex">
          <div class="col-md-4 mb-3">
            <div class="card p-3 border-success h-100">
              <div class="card-body">
                <h3 class="h4 card-title text-success">Quality materials</h3>
                <p class="card-text">
                  We only use the highest quality timber to produce our
                  products, ensuring long-lasting durability and a beautiful
                  finish.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-4 mb-3">
            <div class="card p-3 border-success h-100">
              <div class="card-body">
                <h3 class="h4 card-title text-success">Customer support</h3>
                <p class="card-text">
                  We are committed to providing excellent customer service, from
                  initial enquiry to delivery, ensuring you are satisfied with
                  their purchase every step of the way.
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-4 mb-3">
            <div class="card p-3 border-success h-100">
              <div class="card-body">
                <h3 class="h4 card-title text-success">Customisable options</h3>
                <p class="card-text">
                  We offer a range of species, grades, finishes, profiles and
                  sizes to suit any project, giving you the flexibility to
                  create their perfect look.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
