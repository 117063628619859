import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

// Routes
import Home from "./routes/home";
import Delivery from "./routes/delivery";
import About from "./routes/about";
import Contact from "./routes/contact";
import Firewood from "./routes/firewood";
import Products from "./routes/products";
import Decking from "./routes/products/decking";
import SawnTimber from "./routes/products/sawn-timber";
import Cladding from "./routes/products/cladding";
import Error from "./routes/error";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about-us",
        element: <About />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/delivery-radius",
        element: <Delivery />,
      },
      {
        path: "/firewood",
        element: <Firewood />,
      },
      {
        path: "/products",
        element: <Products />,
      },
      {
        path: "/products/sawn-timber",
        element: <SawnTimber />,
      },
      {
        path: "/products/decking",
        element: <Decking />,
      },
      {
        path: "/cladding",
        element: <Cladding />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
