import { useRouteError } from "react-router-dom";
import { Helmet } from "react-helmet";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import Hero from "../layout/hero";

const title = "Oops!";
const subTitle = "It looks like something went wrong";

export default function Error() {
  const error = useRouteError();

  return (
    <>
      <Header />

      <Helmet>
        <title>{title}</title>
        <meta name="description" content={subTitle} />
      </Helmet>

      <Hero title={title} subTitle={subTitle} />

      <div class="container py-5 px-4 px-lg-3">
        <div class="row">
          <div class="col-lg-7">
            <p>
              <i>{error.statusText || error.message}</i>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
