import { NavLink } from "react-router-dom";

const navitems = [
	{ label: "About us", to: "./about-us" },
	{ label: "Delivery radius", to: "./delivery-radius" },
	{ label: "Firewood", to: "./firewood" },
	{ label: "Contact info", to: "./contact" },
];

export default function Navigation() {
	return (
		<ul class="navbar-nav me-auto mb-2 mb-lg-0 p-3 pb-0 p-lg-0">
			{navitems.map((item) => (
				<li class="nav-item ms-lg-3">
					<NavLink
						to={item.to}
						className={({ isActive, isPending }) =>
							isPending
								? "nav-link pending"
								: isActive
								? "nav-link active"
								: "nav-link"
						}
					>
						{item.label}
					</NavLink>
				</li>
			))}
		</ul>
	);
}
