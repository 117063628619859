import Hero from "../../layout/hero";
import { Helmet } from "react-helmet";

const title = "Our products";
const subTitle = "";

export default function Products() {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={subTitle} />
      </Helmet>

      <Hero title={title} subTitle={subTitle} />

      <div class="container py-5 px-4 px-lg-3">
        <div class="container">
          <div class="d-flex mb-3">
            <div class="flex-grow-1 d-md-none">
              <hr class="border-2 border-top me-3" />
            </div>
            <div class="flex-shrink-1">
              <h2 class="h4">Sawn timber</h2>
            </div>
            <div class="flex-grow-1">
              <hr class="border-2 border-top ms-3" />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
              <div class="card text-bg-dark h-100 d-inline-block">
                <img
                  src="./img/products/boards.jpeg"
                  class="card-img-top"
                  alt="Sawn timber boards"
                />
                <div class="card-body px-4 py-4">
                  <h3 class="card-title">Sawn Timber</h3>
                  <p class="card-text">
                    Our sawn timber is sustainably sourced and cut to size for
                    both construction and DIY. Available in a variety of
                    species, grades and finishes to suit your needs.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 mb-3 mb-lg-0">
              <div class="card text-bg-dark h-100 d-inline-block">
                <img
                  src="./img/products/charred_cladding_3.jpeg"
                  class="card-img-top"
                  alt="Charred cladding"
                />
                <div class="card-body px-4 py-4">
                  <h3 class="card-title">Cladding</h3>
                  <p class="card-text">
                    Profiled planks used for cladding external walls, offering
                    protection and an attractive finish. Available{" "}
                    <b>unfinished, painted or charred</b> in a range of colours.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="card text-bg-dark h-100 d-inline-block">
                <img
                  src="./img/products/pre_aged_douglas_decking.jpeg"
                  class="card-img-top"
                  alt="Pre-aged douglas decking"
                />
                <div class="card-body px-4 py-4">
                  <h3 class="card-title">
                    Decking
                    <span class="badge text-bg-primary align-middle">
                      Popular now
                    </span>
                  </h3>
                  <p class="card-text">
                    Our decking boards create a durable and attractive outdoor
                    living space. Available in a range of finishes to suit any
                    style, our decking boards are the ideal choice for
                    homeowners and businesses alike.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
