import Hero from "../layout/hero";
import { Helmet } from "react-helmet";

const title = "Get in touch";
const subTitle =
  "At Sorwest, we're always ready to assist you with your order or any questions you may have. Our team is here to provide you with the best possible service and support. So, if you're ready to place an order or need help with anything, please don't hesitate to get in touch.";

export default function Contact() {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={subTitle} />
      </Helmet>

      <Hero title={title} subTitle={subTitle} />

      <div class="container py-5 px-4 px-lg-3">
        <div class="row">
          <div class="col-lg-4 mb-5 mb-lg-0">
            <h2 class="h1 mb-lg-5">Sales</h2>
            <p>Contact Will Massingale</p>
            <p>
              <strong>Telephone: </strong>
              <a href="tel:07866680343">07866 680 343</a>
            </p>
            <p>
              <strong>Email: </strong>
              <a href="mailto:will@sorwest.com">will@sorwest.com</a>
            </p>
          </div>
          <hr class="d-lg-none" />
          <div class="col-lg-4 my-5 my-lg-0">
            <h2 class="h1 mb-lg-5">Accounts</h2>
            <p>Contact Sarah Longley</p>
            <p>
              <strong>Email: </strong>
              <a href="mailto:accounts@sorwest.com">accounts@sorwest.com</a>
            </p>
          </div>
          <hr class="d-lg-none" />
          <div class="col-lg-4 mt-5 mt-lg-0">
            <h2 class="h1 mb-lg-5">Location</h2>
            <p>
              <strong>Postal address:</strong> The Barn, High Meadows,
              Morwenstow, Bude, Cornwall EX23 9PH
            </p>
            <p>
              <strong>Yard location:</strong>
            </p>
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2515.5502232590547!2d-4.514492448619033!3d50.91353797944153!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486c7322be8a6985%3A0xd75c8d3a1625d369!2sS%C3%B8rwest%20ltd!5e0!3m2!1sen!2suk!4v1678287386720!5m2!1sen!2suk"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
